import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { type MenuItem } from '../menu-item/menu-item.model';

interface State {
  sidenavItems: MenuItem[];
  currentlyOpen: MenuItem[];
}

const initialState: State = {
  sidenavItems: [],
  currentlyOpen: []
};

export const SidenavStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withDevtools('sidenav'),
  withMethods(store => ({
    setSidenavItems: (menuItems: MenuItem[]) => patchState(store, { sidenavItems: menuItems }),
    setCurrentlyOpenByRoute: (route: string) => {
      let currentlyOpen: MenuItem[] = [];
      const item = findByRouteRecursive(route, store.sidenavItems());
      if (item && item.hasParent()) {
        currentlyOpen = getAllParentItems(item.parent!);
      } else if (item) {
        currentlyOpen = [item];
      }
    }
  }))
);

function findByRouteRecursive(route: string, collection: MenuItem[]) {
  let result = collection.find((item: MenuItem) => item.route === route);

  if (!result) {
    collection.forEach(item => {
      if (item.hasSubItems()) {
        const found = findByRouteRecursive(route, item.subItems);

        if (found) {
          result = found;
          return;
        }
      }
    });
  }

  return result as MenuItem;
}

function getAllParentItems(item: MenuItem, currentlyOpenTemp: MenuItem[] = []) {
  currentlyOpenTemp.unshift(item);

  if (item.hasParent()) {
    return getAllParentItems(item.parent!, currentlyOpenTemp);
  } else {
    return currentlyOpenTemp;
  }
}
